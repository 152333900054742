
import {ref, defineComponent} from 'vue';

import Card from 'primevue/card';

export default defineComponent({
  name: 'Reports',
  components: {
    Card,
  },
  setup() {
    const items = ref([
      {
        id: 1,
        label: 'Поставки',
        to: '/reports/lots',
      },
    ]);

    return {
      items
    }
  }
});
